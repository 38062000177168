import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  Optional,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { debounceTimeAfterFirst } from '@app/_helpers/debounceAfterTime';
import { AppService } from '@app/app.service';
import { ShellComponent } from '@app/shell/shell.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
@UntilDestroy()
@Component({
  selector: 'shell-menu-toggle',
  templateUrl: './shell-menu-toggle.component.html',
  styleUrls: ['./shell-menu-toggle.component.scss'],
  host: {
    '[class.hidden]': '!isMobile',
  },
})
export class ShellMenuToggleComponent implements OnInit, AfterViewInit {
  constructor(
    @Optional()
    private shell: ShellComponent,
    private app: AppService,
    private view: ViewContainerRef,
  ) {}
  @ViewChild('toggle', { read: TemplateRef })
  toggleRef: TemplateRef<any>;
  ngOnInit(): void {}
  ngAfterViewInit(): void {
    if (!this.shell || (!this.app.isTeams() && this.onlyTeams !== false)) {
      this.view.clear();
    } else if (this.toggleRef) {
      this.view.createEmbeddedView(this.toggleRef);
    }
  }
  readonly isMobile$ = this.app.isMobile$.pipe(
    untilDestroyed(this),
    distinctUntilChanged(),
    debounceTimeAfterFirst(50),
  );
  get isMobile() {
    return this.app.isMobile;
  }
  toggleNav() {
    return this.shell?.toggleNav();
  }
  private _onlyTeams = new BehaviorSubject<boolean>(false);
  readonly onlyTeams$ = this._onlyTeams.asObservable().pipe(distinctUntilChanged());
  get onlyTeams() {
    return this._onlyTeams.getValue();
  }
  @Input('teamsOnly')
  set onlyTeams(val: boolean) {
    this._onlyTeams.next(val);
  }
}
